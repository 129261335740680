import React, { Component } from "react";

class Banner extends Component {
  render() {
    return (
      <section className="banner banner-style-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-content">
                <h1>
                  EMPOWERING IT EXCELLENCE FOR <br />
                  <span className={"text-style2"}> 23 YEARS</span>
                </h1>
                <p>
                  Empowering Students Through Modern Instruction and Supportive
                  Learning
                </p>
              </div>
            </div>

            <div className="col-md-12 col-xl-6 col-lg-6">
              <div className="banner-img-round mt-5 mt-lg-0">
                <img
                  src="assets/images/banner/banner_img.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
