import React from "react";
import Layout from "../../common/Layout";
import { PageBanner } from "../layouts/partners";
import { BlogList } from "../layouts/blog";
const Announcement = () => {
  return (
    <Layout>
      <PageBanner
        title="Announcement"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Announcement"
      />
      <BlogList />
    </Layout>
  );
};

export default Announcement;
