import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const client = createClient({
    space: "8n1qdwtv9ie0",
    accessToken: "RF3w20UZvj0a0JOIdls8MOL9AnaBuqLG-6nextu9yVI",
  });

  useEffect(() => {
    const getAllEntries = async () => {
      try {
        const entries = await client.getEntries({ content_type: "post" });
        setBlogPosts(entries.items);
      } catch (error) {
        console.log(`Error fetching blog posts: ${error}`);
      }
    };
    getAllEntries();
  }, []);
  // const excerpt = post.fields.discription.substring(0, 120) + "...";
  return (
    <div id="layout" className="page-wrapper">
      {blogPosts.map((post) => (
        <div className=" container col-xl-6 col-lg-6 col-sm-10 mb-60">
          <div className=" ">
            <div className="row justify-content-between align-items-center">
              <div className="blog-item " key={post.sys.id}>
                <header className="post-thumb">
                  {/* <img
                    src={post.fields.postImage.fields.file.url}
                    alt={post.fields.postTitle}
                    width="100%"
                    height="100%"
                  /> */}
                  <Link to={`/SingleBlog/${post.sys.id}`}>
                    <h3 className="pt-3 ">{post.fields.postTitle}</h3>
                  </Link>
                </header>

                <div className="post-description">
                  {/* {documentToReactComponents(post.fields.discription)} */}
                  <p className="post-meta">
                    By{" "}
                    <a className="text-style2" href="#">
                      {post.fields.author}
                    </a>{" "}
                    Date{" "}
                    <small>
                      {new Intl.DateTimeFormat("en-GB", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      }).format(new Date(post.fields.publishDate))}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
