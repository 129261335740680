import React, { Component } from "react";
import { Link } from "react-router-dom";

class CategoriesType extends Component {
  componentDidMount() {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }

  render() {
    const { data, classes } = this.props;
    return (
      <div
        className={`course-grid course-style-5 bg-white card ${
          classes ? classes : ""
        }`}
      >
        <div className="course-header">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/categorie/${data.image}`}
            alt="Course Thumb"
            className="img-fluid"
            width="100%"
          />
        </div>

        <div className="course-content">
          <h3 className="course-title">
            <Link to={`/category/${data.title}`}>{data.title}</Link>
          </h3>
          <p>{data.description}</p>
          <Link
            to={`/category/${data.title}`}
            className="btn btn-main rounded w-100"
          >
            Visit
          </Link>
        </div>
      </div>
    );
  }
}

export default CategoriesType;
