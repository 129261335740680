import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import ContactUsForm from "./ContactForm";

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [
        {
          id: 1,
          icon: "fa fa-envelope",
          title: "support@corvit.com",
        },
        {
          id: 2,
          icon: "fa fa-phone-alt",
          title: "+92 333 345678",
        },
        {
          id: 3,
          icon: "fa fa-map-marker",
          title: "1st Floor, Ali Tower, University Road, Peshawar, Pakistan ",
        },
      ],
    };
  }
  render() {
    return (
      <section className="contact section-padding">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-heading text-center mb-30">
              <span className="subheading">Get in Touch with Us</span>
              <h2 className="font-lg">Contact Us</h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-4 col-lg-5">
              <div className="contact-info-wrapper mb-5 mb-lg-0">
                <h3>
                  <span>Get in touch</span>
                </h3>
                <p>
                  Have questions or need assistance? We're here to help! Reach
                  out to us through the form, and our team will get back to you
                  as soon as possible. We look forward to hearing from you!
                </p>

                {this.state.contacts.map((data, i) => (
                  <div className="contact-item" key={data.id}>
                    <i className={data.icon}></i>
                    <h5>{data.title}</h5>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-xl-7 col-lg-6">
              <ContactUsForm formStyle="contact__form form-row " />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactInfo;
