import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Link, useParams } from "react-router-dom";
import Layout from "../../../common/Layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Banner } from "../home02";
import { PageBanner } from "../partners";

const SingleBlog = () => {
  const [singleBlogPost, setSingleBlogPost] = useState(null); // Use null initially for better condition checks
  const { id } = useParams();

  const client = createClient({
    space: "8n1qdwtv9ie0",
    accessToken: "RF3w20UZvj0a0JOIdls8MOL9AnaBuqLG-6nextu9yVI",
  });

  useEffect(() => {
    console.log("Fetching entry with ID:", id);
    const getEntryById = async () => {
      try {
        const entry = await client.getEntry(id);
        console.log("Fetched entry:", entry); // Inspect the entire entry object
        setSingleBlogPost(entry);
      } catch (error) {
        console.log(`Error fetching blog post: ${error}`);
      }
    };
    getEntryById();
  }, [id]);

  if (!singleBlogPost) {
    return <div>Loading...</div>; // Provide a loading state
  }
  const options = {
    // options for rendering rich text
  };
  return (
    <Layout>
      <PageBanner
        title={singleBlogPost.fields.postTitle}
        rootUrl="/Announcement"
        parentUrl="Announcement"
        currentUrl={singleBlogPost.fields.postTitle}
      />
      <div id="layout" className="page-wrapper">
        <div className="container">
          <div>
            <div className="">
              <section className="">
                <header className="">
                  <img
                    src={singleBlogPost.fields.postImage.fields.file.url}
                    alt={singleBlogPost.fields.postTitle}
                    width="100% "
                    height="100% "
                  />
                  <h3 className="pt-3 ">{singleBlogPost.fields.postTitle}</h3>
                  <p className="">
                    By{" "}
                    <span
                      href="https://thecodeangle.com/"
                      className="text-style2"
                    >
                      {singleBlogPost.fields.author}
                    </span>{" "}
                    Date{" "}
                    <small>
                      {new Intl.DateTimeFormat("en-GB", {
                        month: "long",
                        day: "2-digit",
                        year: "numeric",
                      }).format(new Date(singleBlogPost.fields.publishDate))}
                    </small>
                  </p>
                </header>
                <div className="">
                  {typeof singleBlogPost.fields.discription === "object"
                    ? documentToReactComponents(
                        singleBlogPost.fields.discription,
                        options
                      )
                    : singleBlogPost.fields.discription}
                  {typeof singleBlogPost.fields.postContent === "object"
                    ? documentToReactComponents(
                        singleBlogPost.fields.postContent,
                        options
                      )
                    : singleBlogPost.fields.postContent}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SingleBlog;
