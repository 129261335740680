import React, { useRef, useEffect, useState } from "react";

const Nav = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const spanElement = document.createElement("span");
      spanElement.className = "menu-trigger";
      spanElement.innerHTML = '<i class="fal fa-angle-down"></i>';
      containerRef.current.appendChild(spanElement);
    }
  }, []);

  const [menuItems, setMenuItems] = useState([
    {
      label: "Home",
      link: "/",
      class: "current",
    },
    {
      label: "Courses",
      link: "#",
      submenu: [
        {
          label: "Artificial Intelligence",
          link: "/category/Artificial Intelligence",
        },
        {
          label: "Network Administration",
          link: "/category/Network Administration",
        },
        { label: "Cloud Computing", link: "/category/Cloud Computing" },
        { label: "Cyber Security", link: "/category/Cyber Security" },
        { label: "Web Development", link: "/category/Web Development" },
        { label: "Project Management", link: "/category/Project Management" },
        // { label: "Course Single 1", link: "/course-details/1" },
        // { label: "Course Single 2", link: "/course-details-two/2" },
      ],
    },
    {
      label: "Announcement",
      link: "/Announcement",
    },
    {
      label: "Class Schedule",
      link: "/classschedule",
    },
    {
      label: "More",
      link: "#",
      submenu: [{ label: "Instructors", link: "/instructors" }],
      submenuOpen: false,
    },
    {
      label: "About",
      link: "/about",
    },
    {
      label: "Contact",
      link: "/contact",
    },

    // Add more menu items as needed
  ]);
  useEffect(() => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) =>
        item.submenu && item.submenu.length > 0
          ? { ...item, hasSubmenu: true }
          : item
      )
    );
  }, []);

  const handleSubmenuToggle = (index) => {
    setMenuItems((prevMenuItems) => {
      const newMenuItems = [...prevMenuItems];
      newMenuItems[index].submenuOpen = !newMenuItems[index].submenuOpen;
      return newMenuItems;
    });
  };

  return (
    <ul className="primary-menu">
      {menuItems.map((item, index) => (
        <li key={index}>
          <a href={item.link}>{item.label}</a>

          {item.submenu && (
            <span
              className={`menu-trigger ${item.submenuOpen ? "open" : " "}`}
              onClick={() => handleSubmenuToggle(index)}
            >
              <i
                className={`submenu-icon ${
                  item.submenuOpen ? "fal fa-angle-up" : "fal fa-angle-down"
                }`}
              ></i>
            </span>
          )}
          {item.submenu && (
            <ul className={`submenu ${item.submenuOpen ? "open" : ""}`}>
              {item.submenu.map((subitem, subindex) => (
                <li key={subindex}>
                  <a href={subitem.link}>{subitem.label}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
export default Nav;
