import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

const CourseInfo = ({ data }) => {
  const [toggler, setToggler] = useState(false);
  return (
    <div className="course-sidebar course-sidebar-2 mt-5 mt-lg-0">
      <div className="course-widget course-details-info">
        <div className="price-header">
          <h2 className="course-price">
            Rs. {data.price === "0" ? "Free" : data.price}{" "}
            <span>Rs. {data.oldPrice}</span>
          </h2>
          <span className="course-price-badge onsale">20% off</span>
        </div>

        <ul className="course-sidebar-list">
          {data.level && (
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  <i className="ri-bar-chart-2-line"></i>Level
                </span>
                {data.level}
              </div>
            </li>
          )}

          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="ri-time-line"></i>Duration
              </span>
              {data.duration > 1 ? `${data.duration} Months` : "Month"}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="ri-translate"></i>Language
              </span>
              {data.language}
            </div>
          </li>
        </ul>
        <div className="buy-btn">
          <button className="button button-enroll-course btn btn-main-2 rounded">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
