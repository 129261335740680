import React, { Component } from "react";
import Slider from "react-slick";
import { HomeTwoTestimonial } from "../../../utils/script";

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: [
        {
          id: 1,
          img: "assets/images/partners/partners-01.jpg",
        },
        {
          id: 2,
          img: "assets/images/partners/partners-02.jpg",
        },
        {
          id: 3,
          img: "assets/images/partners/partners-03.jpg",
        },
        {
          id: 4,
          img: "assets/images/partners/partners-04.jpg",
        },
        {
          id: 5,
          img: "assets/images/partners/partners-05.jpg",
        },
        {
          id: 6,
          img: "assets/images/partners/partners-06.jpg",
        },
        {
          id: 7,
          img: "assets/images/partners/partners-07.jpg",
        },
        {
          id: 8,
          img: "assets/images/partners/partners-08.jpg",
        },
        {
          id: 9,
          img: "assets/images/partners/partners-09.jpg",
        },
        {
          id: 10,
          img: "assets/images/partners/partners-10.jpg",
        },
        {
          id: 11,
          img: "assets/images/partners/partners-11.jpg",
        },
      ],
    };
  }

  render() {
    return (
      <section>
        <div className="container-fluid container-grid">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-heading text-center mb-30">
                <span className="subheading">Together We Achieve More</span>
                <h2 className="font-lg">Our Partners</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12 col-xl-12">
              <div className="testimonials-slides-3 mb-30">
                <Slider {...HomeTwoTestimonial}>
                  {this.state.partners.map((data, i) => (
                    <img src={data.img} alt="" className="img-fluid" />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;
