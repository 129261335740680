import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      <section className="footer">
        <div className="footer-mid">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 me-auto col-sm-8">
                <div className="footer-logo mb-3">
                  <img
                    src="assets/images/logo-white.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="widget footer-widget mb-5 mb-lg-0">
                  <p>
                    We focus on technologies like Routing & Switching, Cyber
                    Security, Cloud Computing, DevOps, Ethical Hacking, Web
                    Development, Graphic Designing, SEO, Digital Marketing,
                    Operating Systems, etc.
                  </p>
                </div>
              </div>

              <div className="col-xl-2 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title">Explore</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/contact">Support</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title ">Categories</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="/category/Artificial Intelligence">
                        Artificial Intelligence
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Network Administration">
                        Network Administration
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Cloud Computing">
                        Cloud Computing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/category/Cyber Security
"
                      >
                        Cyber Security
                      </Link>
                    </li>
                    <li>
                      <Link to="/category/Web Development">
                        Web Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title">Links</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <Link to="#">News & Blogs</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Support</Link>
                    </li>
                    <li>
                      <Link to="#">Return Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-2 col-sm-4">
                <div className="footer-widget mb-5 mb-xl-0">
                  <h5 className="widget-title">Address</h5>
                  <ul className="list-unstyled footer-links">
                    <li>
                      <h6 className="text-white">Phone</h6>
                      <Link to="#">+9233 123 4567</Link>
                    </li>
                    <li>
                      <h6 className="text-white">Email</h6>
                      <Link to="#">support@corvit.com</Link>
                    </li>
                  </ul>
                  <div className="footer-socials mt-4">
                    <Link to="#">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                    <Link to="#">
                      <i className="fab fa-pinterest"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-btm">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-sm-12 col-lg-6">
                <p className="mb-0 copyright text-sm-center text-lg-start">
                  © 2024 Corvit All rights reserved by{" "}
                  <Link to="#" rel="nofollow">
                    Corvit
                  </Link>{" "}
                </p>
              </div>
              <div className="col-xl-6 col-sm-12 col-lg-6">
                <div className="footer-btm-links text-start text-sm-center text-lg-end">
                  <Link to="#">Legal</Link>
                  <Link to="#">Supports</Link>
                  <Link to="#">Terms</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-btm-top">
          <Link to="#top-header" className="js-scroll-trigger scroll-to-top">
            <i className="fa fa-angle-up"></i>
          </Link>
        </div>
      </section>
    </>
  );
};

export default FooterTwo;
