import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchCourses } from "../../../services/contentful";
import styles from "./CourseTable.module.css"; // Import your CSS module

const CourseTable = () => {
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const coursesPerPage = 10; // Number of courses to show per page
  const navigate = useNavigate();

  useEffect(() => {
    const loadCourses = async () => {
      const data = await fetchCourses();
      setCourses(data);
    };
    loadCourses();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
  };

  const handleButtonClick = (course) => {
    navigate("/form", { state: { course } });
  };

  const filteredCourses = courses.filter((course) => {
    return (
      course.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedBranch === "" || course.branch === selectedBranch) &&
      (selectedField === "" || course.field === selectedField)
    );
  });

  // Pagination logic
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = filteredCourses.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  const totalPages = Math.ceil(filteredCourses.length / coursesPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Define branches and fields before using them
  const branches = [...new Set(courses.map((course) => course.branch))];
  const fields = [...new Set(courses.map((course) => course.field))];

  return (
    <div className="contact section-padding ">
      <div className="">
        <div className={styles.container}>
          <div className={styles.filters}>
            <input
              type="text"
              placeholder="Search by course name"
              value={searchQuery}
              onChange={handleSearchChange}
              className={styles.input}
            />
            <select
              value={selectedBranch}
              onChange={handleBranchChange}
              className={styles.select}
            >
              <option value="">All Branches</option>
              {branches.map((branch, index) => (
                <option key={index} value={branch}>
                  {branch}
                </option>
              ))}
            </select>
            <select
              value={selectedField}
              onChange={handleFieldChange}
              className={styles.select}
            >
              <option value="">All Fields</option>
              {fields.map((field, index) => (
                <option key={index} value={field}>
                  {field}
                </option>
              ))}
            </select>
          </div>
          <div className="overflow-x-auto">
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableHeader}>
                  <th className={styles.tableCell}>Course Name</th>
                  <th className={styles.tableCell}>Field</th>
                  <th className={styles.tableCell}>Mode of Training</th>
                  <th className={styles.tableCell}>Trainer</th>
                  <th className={styles.tableCell}>Day</th>
                  <th className={styles.tableCell}>Starting Date</th>
                  <th className={styles.tableCell}>Timing</th>
                  <th className={styles.tableCell}>Branch</th>
                  <th className={styles.tableCell}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentCourses.map((course) => (
                  <tr key={course.id} className={styles.rowHover}>
                    <td className={` ${styles.tableCell} ${styles.courseName}`}>
                      {course.name}
                    </td>
                    <td className={styles.tableCell}>{course.field}</td>
                    <td className={styles.tableCell}>{course.mode}</td>
                    <td className={styles.tableCell}>{course.trainer}</td>
                    <td className={styles.tableCell}>{course.day}</td>
                    <td className={styles.tableCell}>{course.startDate}</td>
                    <td className={styles.tableCell}>{course.timing}</td>
                    <td className={styles.tableCell}>{course.branch}</td>
                    <td className={styles.tableCell}>
                      <button
                        onClick={() => handleButtonClick(course)}
                        className={styles.button}
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination Buttons */}
          <div className={`${styles.pagination} ${styles.topP}`}>
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={styles.paginationButton}
            >
              Previous
            </button>
            <span className={styles.paginationInfo}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={styles.paginationButton}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTable;
