import React from "react";
import Layout from "../../common/Layout";
import { PageBanner } from "../layouts/partners";
import { CourseTable } from "../layouts/schedule";

const CategoryPage = () => {
  return (
    <Layout>
      <PageBanner
        title="Class Schedule"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Class Schedule"
      />
      <div className=" container-grid ">
        <CourseTable />
      </div>
    </Layout>
  );
};

export default CategoryPage;
